*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.nav-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 90%;
  max-width: 200px;
  background-color: white;
  color: #55637c;
  transition: transform 300ms;
  z-index: 20;
}

.nav-bar--hidden {
  transform: translateX(-100%);
}

.nav-bar--visible {
  transform: translateX(0);
}

.nav-bar__back-button {
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 2rem;
  min-height: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 300ms;
}

.nav-bar__back-button:hover {
  transform: scale(1.1);
  color: #92011e;
}

.nav-bar__header {
  height: 10%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.nav-bar__menu {
  display: flex;
  flex-direction: column;
  height: 80%;
  padding-top: 4rem;
  align-items: center;
  gap: 2rem;
}

.nav-bar__menu a {
  display: block;
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  color: inherit;
  text-align: center;
  transition: all 300ms;
}

.nav-bar__menu a:hover {
  color: #92011e;
  transform: scale(1.1);
}

.nav-bar__footer {
  height: 10%;
  color: #55637c;
  display: grid;
  grid-template-columns: 1fr;
}

.nav-bar__footer a {
  color: inherit;
  display: block;
  transition: all 300ms;
}

.nav-bar__footer a:hover {
  color: #92011e;
  transform: scale(1.1);
}

.nav-bar__footer section {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.nav-bar__footer span {
  font-family: "Oswald", sans-serif;
  font-size: 0.8rem;
}

.header__menu-button {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  cursor: pointer;
  color: #55637c;
  height: 2rem;
  width: 6rem;
  z-index: 10;
}

.header__menu-button__text {
  font-family: "Oswald", sans-serif;
  padding: 0.1em 0.4em 0.1em 0;
}

.main-container {
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  justify-items: center;
}

.main-container p {
  text-align: center;
  font-size: 64px;
  font-family: "Oswald", sans-serif;
  color: white;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.2rem;
  text-shadow: 1px 1px 1px #55637c;
}

.main-container a {
  text-decoration: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 6px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1px;
  font-family: "Heebo", sans-serif;
  letter-spacing: 0.2rem;
  max-width: 9em;
  text-shadow: 1px 1px 1px #55637c;
}

.main-container a:hover {
  background-color: white;
  color: #55637c;
}

.main-container__content {
  position: absolute;
  display: grid;
  gap: 2rem;
  place-items: center;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr 1fr;
}

.slides {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fbf5ef;
}

.slide {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.6;
}

img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0 none;
}

.animate-zoom {
  -webkit-animation-name: zoom;
          animation-name: zoom;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.visible {
  display: block;
  -webkit-animation: visibility 1500ms forwards;
          animation: visibility 1500ms forwards;
  -webkit-animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.invisible {
  display: none;
}

.site-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25% 50% 25%;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: baseline;
  margin-top: 1rem;
}

.header__title {
  margin: 0 auto;
}

.header__title > h1 {
  font-family: "Oswald", sans-serif;
  margin: 0;
  font-size: 1.5rem;
  color: white;
  text-shadow: 1px 1px 1px #55637c;
}

.header__right-spacer {
  width: 6rem;
}

@media screen and (min-width: 420px) {
  .header__title > h1 {
    font-size: 2rem;
  }
}

@-webkit-keyframes zoom {
  100% {
    transform: scale(1.1);
  }
}

@keyframes zoom {
  100% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes visibility {
  100% {
    opacity: 1;
  }
}

@keyframes visibility {
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.b92f5b2e.css.map */
