@use "palette";

.header__menu-button {
  background-color: palette.$color-white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  cursor: pointer;
  color: palette.$color-serenity;
  height: 2rem;
  width: 6rem;
  z-index: 10;
}

.header__menu-button__text {
  font-family: "Oswald", sans-serif;
  padding: 0.1em 0.4em 0.1em 0;
}
