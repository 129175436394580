@use "palette";

.main-container {
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  justify-items: center;

  p {
    text-align: center;
    font-size: 64px;
    font-family: "Oswald", sans-serif;
    color: white;
    font-weight: 600;
    margin: 0;
    letter-spacing: 0.2rem;
    text-shadow: 1px 1px 1px palette.$color-serenity;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    border: 1px solid white;
    padding: 6px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1px;
    font-family: "Heebo", sans-serif;
    letter-spacing: 0.2rem;
    max-width: 9em;
    text-shadow: 1px 1px 1px palette.$color-serenity;
  }

  a:hover {
    background-color: white;
    color: palette.$color-serenity;
  }
}

.main-container__content {
  position: absolute;
  display: grid;
  gap: 2rem;
  place-items: center;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr 1fr;
}
