@use "reset";
@use "nav-bar";
@use "menu-button";
@use "palette";
@use "main";

.slides {
  height: 100%;
  width: 100%;
  position: absolute;
  background: palette.$color-champagne;
}

.slide {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.6;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0 none;
}

.animate-zoom {
  animation-name: zoom;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.visible {
  display: block;
  animation: visibility 1500ms forwards;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.invisible {
  display: none;
}

.site-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25% 50% 25%;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: baseline;
  margin-top: 1rem;
}

.header__title {
  margin: 0 auto;
}

.header__title > h1 {
  font-family: "Oswald", sans-serif;
  margin: 0;
  font-size: 1.5rem;
  color: palette.$color-white;
  text-shadow: 1px 1px 1px palette.$color-serenity;
}

.header__right-spacer {
  width: 6rem;
}

@media screen and (min-width: 420px) {
  .header__title > h1 {
    font-size: 2rem;
  }
}

@keyframes zoom {
  100% {
    transform: scale(1.1);
  }
}

@keyframes visibility {
  100% {
    opacity: 1;
  }
}
