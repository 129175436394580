@use "palette";

.nav-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 90%;
  max-width: 200px;
  background-color: palette.$color_white;
  color: palette.$color-serenity;
  transition: transform 300ms;
  z-index: 20;
}

.nav-bar--hidden {
  transform: translateX(-100%);
}

.nav-bar--visible {
  transform: translateX(0);
}

.nav-bar__back-button {
  text-align: center;
  width: fit-content;
  min-width: 2rem;
  min-height: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 300ms;
}

.nav-bar__back-button:hover {
  transform: scale(1.1);
  color: palette.$color-crimson;
}

.nav-bar__header {
  height: 10%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.nav-bar__menu {
  a {
    display: block;
    width: 100%;
    font-family: "Oswald", sans-serif;
    font-size: 1.5rem;
    text-decoration: none;
    color: inherit;
    text-align: center;
    transition: all 300ms;
  }

  a:hover {
    color: palette.$color-crimson;
    transform: scale(1.1);
  }

  display: flex;
  flex-direction: column;
  height: 80%;
  padding-top: 4rem;
  align-items: center;
  gap: 2rem;
}

.nav-bar__footer {
  height: 10%;
  color: palette.$color-serenity;
  display: grid;
  grid-template-columns: 1fr;

  a {
    color: inherit;
    display: block;
    transition: all 300ms;
  }

  a:hover {
    color: palette.$color-crimson;
    transform: scale(1.1);
  }

  section {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  span {
    font-family: "Oswald", sans-serif;
    font-size: 0.8rem;
  }
}
